





























































import { Component, Prop, Vue } from 'vue-property-decorator'
import PaginationButton from './PaginationButton.vue';
import NumPerPageSelect from './NumPerPageSelect.vue';

@Component({ 
  components:{ 
    PaginationButton,
    NumPerPageSelect
  }
})
export default class SimplePagination extends Vue {
  

  @Prop({ default: 1 })
  page!: number;

  @Prop({ default: 10 })
  numPerPage!: number;

  @Prop({ type: Number })
  total!: number;

  get numPages (): number {
    return Math.max(Math.ceil(this.total / this.numPerPage), 1)
  }

  get startItemNumber () : number {
    return (this.page - 1) * this.numPerPage + 1
  }

  get endItemNumber () : number {
    return Math.min(this.page * this.numPerPage, this.total)
  }

  get shownPageNumbers () : number [] { 
    const numbers: number[] = [];
    if (this.page > 2){ 
      numbers.push(this.page - 2);
    }
    if (this.page > 1){ 
      numbers.push(this.page -1);
    }
    numbers.push(this.page);
    if (this.page < this.numPages ){ 
      numbers.push(this.page + 1);
    }
    if (this.page < this.numPages - 1){ 
      numbers.push(this.page + 2);
    }
    return numbers;
  }

  goToPage (page: number)  {
    this.$emit('page', page);
  }

  async onNumPerPageChange(numPerPage: string){
    this.$emit('numperpage', numPerPage);
  }
}
